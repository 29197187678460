import React from "react"

import Heading from "../components/Heading/Heading"
import Layout from "../components/Layout/Layout"
import Paragraph from "../components/Paragraph/Paragraph"
import Section from "../components/Section/Section"
import Segment from "../components/Segment/Segment"


const NotFoundPage = () => (
  <Layout>
    <Section>
      <Segment>
        <Heading level={1} modifiers={["major"]}>Not found</Heading>
        <Paragraph>Unfortunately the page you are trying to reach does not exist!</Paragraph>
      </Segment>
    </Section>
  </Layout>
)

export default NotFoundPage
