import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Disqus from "gatsby-plugin-disqus"
import { Link } from "gatsby"
import { Location } from "@reach/router"

import Layout from "../components/Layout/Layout"
import Content, { HTMLContent } from "../components/Content"
import Heading from "../components/Heading/Heading"
import Section from "../components/Section/Section"
import Segment from "../components/Segment/Segment"
import Share from "../components/Share/Share"
import useSiteMetadata from "../components/SiteMetadata"
import SubscribeForm from "../components/MailChimp/SubscribeForm"
import NotFoundPage from "../pages/404"


export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  subtitle,
  date,
  isoDate,
  titleImage,
  helmet,
  slug,
  next,
  previous
}) => {
  const PostContent = contentComponent || Content
  const { siteUrl, twitterHandle } = useSiteMetadata()

  return (
    <div itemScope itemType="http://schema.org/BlogPosting">
      {helmet || ""}
      <Section image={titleImage} modifiers={["bg-image"]}>
        <Segment>
          <Heading level={1} modifiers={["hero"]} itemProp="headline name">{title}</Heading>
          <Heading level={0} modifiers={["hero-subtitle"]} itemProp="description">{subtitle}</Heading>
          <Heading level={0} modifiers={["hero-date"]} itemProp="datePublished" content={isoDate}>{date}</Heading>
        </Segment>
      </Section>

      <Section>
        <Segment>
          <article itemProp="articleBody">
            <Location>
              {({location}) => (
                <Share
                  url={`${siteUrl}${location.pathname}`}
                  title={title}
                  twitterHandle={twitterHandle}
                  small={true}
                  greyscale={false}
                />
              )}
            </Location>
            <PostContent className="cms-content cms-content--blog" content={content} />
            <Heading level={0} modifiers={["major", "centred", "no-margin"]}>Share</Heading>
            <Location>
              {({location}) => (
                <Share url={`${siteUrl}${location.pathname}`} title={title} twitterHandle={twitterHandle}/>
              )}
            </Location>
          </article>
          {/*tags && tags.length ? (
            <ul className="tag-list">
              {tags.map(tag => (
                <li key={tag + `tag`} className="tag-list__item">
                  <Link className="tag-list__link" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </li>
              ))}
            </ul>
          ) : ""*/}
          <div>
            <hr className="rule"/>
            <Heading level={0} modifiers={["minor"]}>Continue reading</Heading>
            {previous ? <Link className="link link--previous" to={previous.slug}>{previous.title}</Link> : ""}
            {next ? <Link className="link link--next" to={next.slug}>{next.title}</Link> : ""}
          </div>
        </Segment>
      </Section>

      <Section modifiers={["shaded"]}>
        <Segment>
          <Heading level={1} modifiers={["major", "centred"]}>Subscribe to the newsletter</Heading>
          {/*<Paragraph modifiers={["centred"]}>Keep informed by subscribing to our monthly newsletter.</Paragraph>*/}
          <SubscribeForm id="subscribe" stacked={false} centred={true}/>
        </Segment>
      </Section>

      <Section>
        <Segment id="comments">
          <Location>
            {({location}) => (
              <Disqus
                identifier={slug}
                title={title}
                url={`${siteUrl}${location.pathname}`}
              />
            )}
          </Location>
        </Segment>
      </Section>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  isoDate: PropTypes.string,
  titleImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
  next: PropTypes.shape({slug: PropTypes.string, title: PropTypes.string}),
  previous: PropTypes.shape({slug: PropTypes.string, title: PropTypes.string}),
}

const BlogPost = ({ pageContext, data }) => {
  const { markdownRemark: post } = data
  const { siteUrl } = useSiteMetadata()

  if (!post.frontmatter.visible || Date.parse(post.frontmatter.isoDate) > new Date()) {
    return <NotFoundPage/>
  }

  let ogImage = null
  if (post.frontmatter.ogImage){
    ogImage = post.frontmatter.ogImage.childImageSharp.fixed
  }
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        date={post.frontmatter.date}
        isoDate={post.frontmatter.isoDate}
        helmet={
          <Helmet titleTemplate="%s | Trusty Canary">
            <title>{`${post.frontmatter.title}`}</title>
            <meta property="og:url" content={`${siteUrl}/${post.fields.slug}`}/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={post.frontmatter.title}/>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:description"
              content={`${post.frontmatter.description}`}
            />
            {ogImage ? <meta property="og:image" content={`${siteUrl}${ogImage.src}`}/> : ""}
            {ogImage ? <meta property="og:image:height" content={ogImage.height}/> : ""}
            {ogImage ? <meta property="og:image:width" content={ogImage.width}/> : ""}

            <meta property="article:published_time" content={post.frontmatter.isoDate}/>
            <meta property="article:section" content="Home"/>
            {
              post.frontmatter.tags ? (
                post.frontmatter.tags.map(tag => (<meta property="article:tag" content={tag} key={tag}/>))
              ) : ""
            }
          </Helmet>
        }
        slug={post.fields.slug}
        tags={post.frontmatter.tags}
        titleImage={post.frontmatter.titleImage}
        next={pageContext.next}
        previous={pageContext.previous}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
  pageContext: PropTypes.object
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        isoDate: date
        visible
        title
        subtitle
        description
        ogImage: image {
          childImageSharp {
            fixed(
              width: 1200,
              quality: 100
            ) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        titleImage: image {
          childImageSharp {
            fluid(
              maxWidth: 2048,
              quality: 100,
              duotone: {
                highlight: "#70656b",
                shadow: "#000000"
              }
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tags
      }
    }
  }
`
